<template>
<div :class="{'height-center':isMobile}">
  <LoginTemplate v-if="!isMobile">
    <div class="grid-menu">
      <div data-anima="top" v-if="!selectPainel">
        <button class="btnToGreenn" @click="openGreenn">
          <img src="@/assets/logo.png" />
          Iniciar sessão com Greenn
        </button>
      </div>
      <div v-else class="list-size">
        <ListSites :isMobile="isMobile" :painels="sites" @selectSite="select"></ListSites>
      </div>
    </div>
  </LoginTemplate>
  <div v-else class="center-mobile">
    <div data-anima="top" v-if="!selectPainel" class="center-btn">
      <button class="btnToGreenn" @click="openGreenn">
        <img src="@/assets/logo.png" />
        Iniciar sessão com Greenn
      </button>
    </div>
    <div v-else class="list-size">
      aqui
      <ListSites :isMobile="isMobile" :painels="sites" @selectSite="select"></ListSites>
    </div>
  </div>
</div>
</template>

<script>
import LoginTemplate from "@/templates/LoginTemplate.vue";
import ListSites from "@/components/ListSites.vue";
import UserService from "@/services/resources/UserService";
const service = UserService.build();

export default {
  name: "Login",
  components: {
    LoginTemplate,
    ListSites
  },
  data() {
    return {
      loading: false,
      success: true,
      email: "",
      password: "",
      selectPainel: false,
      sites: [],
      client: {
        width: 0,
      },
    };
  },
  mounted() {
    this.getParams();
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
    select(e){
      this.$store.dispatch("registerLogin", e.Auth).then(() => {
        this.$router.push("/dashboard");
      });
    },
    getParams() {
      var queryString = window.location.search;
      if (queryString !== "") {
        const urlParams = new URLSearchParams(queryString);
        const params = urlParams.get("rdr");
        if (params === "" || params === null || params === undefined) {
          return false;
        } else {
          service
            .read(`/auth/${params}?type=greenn`)
            .then((e) => {
              console.log(e);
              this.$store.dispatch("saveMember", JSON.stringify(e));
              this.sites = e.sites;
              if(this.sites.length === 1){
                this.select(e.sites[0]);
                return
              }
              this.selectPainel = true;
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    openGreenn() {
      window.location.href = process.env.VUE_GREENN_ADM_HOST + "?type=envios";
    },
    mostrarSenha() {
      const input = document.querySelector("#password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_senha.innerText = "Ocultar";
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_senha.innerText = "Mostrar";
      }
    },
    onSubmit() {
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.success = true;
          this.loading = true;
          let data = {
            username: this.email,
            password: this.password,
          };

          this.$store
            .dispatch("loginRequest", data)
            .then((e) => {
              console.log(e);
              this.$router.push("/dashboard");
              this.success = true;
            })
            .catch((err) => {
              console.log(err);
              this.success = false;
            })
            .finally(() => {
              this.submit = false;
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-menu {
  width: 100%;
}

.list-size{
  width: 35vw;
}

.btnToGreenn {
  background: transparent;
  border: 0.5px solid #ededf0;
  color: #333;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  border-radius: 5px;
  font-size: 14px;
  width: 36vw;
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 10px;
  img {
    width: 75px;
    margin-left: -20px;
    margin-top: 2px;
  }
}

.height-center{
  height: 100vh;
  width: 100%;
}

.center-btn{
  display: flex;
  justify-content: center;
  .btnToGreenn{
    width: 96% !important;
  }
}

.center-mobile{
  position: relative;
  top: 50%;
  transform: translateY(-50%); 
}
</style>
